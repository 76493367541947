import { ApiCall } from "../apiBase";

export async function getAllDestinations() {
  let destinations = [];

  const result = await ApiCall({
    method: "post",
    data: {
      query: `
            query Query {
              getDestinationList {
                id
                destinationTitle
                destinationProvince
                destinationDescription
                destinationTier
                destinationImageList
              }
            }
            `
    }
  });

  if (!result.data.errors && result.data.data.getDestinationList) {
    destinations = result.data.data.getDestinationList;
  }

  return destinations;
}

export async function getAllDestinationsPaginated(filters) {
  let destinations = [];

  const { page, itemsPerPage, searchTerm } = filters || {};

  const result = await ApiCall({
    method: "post",
    data: {
      query: `
            query Query($getDestinationListPaginatedDestinationFilters: DestinationFilters) {
              getDestinationListPaginated(destinationFilters: $getDestinationListPaginatedDestinationFilters) {
                data {
                  id
                  destinationTitle
                  destinationProvince
                  destinationDescription
                  destinationTier
                  destinationImageList
                }
                count
              }
            }
            `,
      variables: {
        getDestinationListPaginatedDestinationFilters: {
          page,
          itemsPerPage,
          searchTerm
        }
      }
    }
  });

  if (!result.data.errors && result.data.data.getDestinationListPaginated) {
    destinations = result.data.data.getDestinationListPaginated;
  }
  else {
    throw Error(result?.data?.errors[0]);
  }

  return destinations;
}

export async function getDestinationNamesList(searchTerm) {
  let destinations = [];

  const result = await ApiCall({
    method: "post",
    data: {
      query: `
            query Query($destinationName: String) {
              getDestinationList(searchTerm: $destinationName) {
                id
                destinationTitle
              }
            }
            `,
      variables: {
        destinationName: searchTerm
      }
    }
  });

  if (!result.data.errors && result.data.data.getDestinationList) {
    destinations = result.data.data.getDestinationList;
  }
  else {
    throw Error(result?.data?.errors[0]);
  }

  return destinations;
}

export async function getDestinationById(destinationId) {
  let destination = null;

  const result = await ApiCall({
    method: "post",
    data: {
      query: `
            query Query($getDestinationByIdDestinationId: ID!) {
              getDestinationById(destinationId: $getDestinationByIdDestinationId) {
                id
                destinationTitle
                destinationProvince
                destinationDescription
                destinationTier
                destinationImageList
              }
            }
            `,
      variables: {
        getDestinationByIdDestinationId: destinationId
      }
    }
  });

  if (!result.data.errors && result.data.data.getDestinationById) {
    destination = result.data.data.getDestinationById;
  }
  else {
    throw Error(result?.data?.errors[0]);
  }

  return destination;
}
