<script>
// @ is an alias to /src
// eslint-disable-next-line import/no-cycle
import router from "@/router/index";
import CommonLayout from "@/views/CommonLayout.vue";
import LandscapeCard from "@/components/LandscapeCard.vue";
import PortraitCard from "@/components/PortraitCard.vue";
import ReviewCard from "@/components/ReviewCard.vue";
import Search from "@/components/Search.vue";
import PreLoader from "@/components/PreLoader.vue";

import * as reviewService from "@/api/services/review.service";
import * as categoryService from "@/api/services/category.service";
import * as experienceService from "@/api/services/experience.service";
import * as destinationService from "@/api/services/destination.service";

import Vue from "vue";
import VueObserveVisibility from "vue-observe-visibility";

import AppButton from "../components/AppButton.vue";

import destinationsIcon from "@/assets/icons/home-destinations.svg";
import experiencesIcon from "@/assets/icons/home-experiences.svg";

Vue.use(VueObserveVisibility);

export default {
  name: "Home",
  components: {
    CommonLayout,
    LandscapeCard,
    Search,
    PortraitCard,
    ReviewCard,
    PreLoader,
    AppButton
  },

  data() {
    return {
      categoryList: [],
      destinationPlaces: [],
      experiencePlaces: [],
      reviews: [],
      dragged: false,
      dragTimeout: null,
      isLoading: true,
      searchTerm: null,
      slickOptions: {
        arrows: false,
        infinite: 6,
        slidesToShow: 3,
        slidesToScroll: 2,
        cssEase: "linear"
      },
      showLoader: true,
      portraitSlickOptions: {
        arrows: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        cssEase: "linear"
      },
      isMobile: false,
      dropdownSelection: null,
      carouselItemCount: 8,
      isActive: false,
      isShowing: false,
      showErrorComponent: false,
      destinationsIconPath: destinationsIcon,
      experiencesIconPath: experiencesIcon
    };
  },
  computed: {
    checkLoading() {
      return this.isLoading;
    },
    checkDataAvailable() {
      if (this.destinationPlaces && this.destinationPlaces.length > 0) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    this.showLoader = true;
    this.detectMob();
    this.isLoading = true;
    this.getPopularExperiences();
    this.getDestinationsList();
    this.getExperienceCategories();
    this.getTravelerReviewList();
    //this.handleSlider();
    this.setTimerForLoader();
    this.$refs.horizontal.scrollToIndex(1);
  },
  beforeMount() {
    window.scrollTo({ top: 0, left: 0 });
  },

  methods: {
    clickOnScrolleButton() {
      let pageHeight = window.innerHeight;
      window.scrollTo(0, pageHeight);
    },
    onCardPress(placeId) {
      if (!this.dragged) {
        router.push({ name: "ExperienceDetail", params: { placeId } });
      }
    },
    onDragStart() {
      clearTimeout(this.dragTimeout);

      this.dragged = false;
      this.dragTimeout = setTimeout(() => {
        this.dragged = true;
      }, 100); // Minimal delay to be regarded as drag instead of click
    },
    onDragClick(e) {
      if (this.dragged) {
        e.preventDefault();
      }
      this.dragged = false;
    },
    async getDestinations() {
      this.isLoading = true;

      const filters = {
        page: this.currentPage,
        itemsPerPage: this.cardsPerPage
      };
      try {
        if (this.searchTerm) {
          filters.searchTerm = this.searchTerm;
        }
        await this.getDestinationList(filters);
      } catch (error) {
        this.showErrorComponent = true;
      }
    },
    async getDestinationList(filters) {
      try {
        const destinationResponse = await destinationService.getAllDestinations(filters);
        this.destinationPlaces = await destinationResponse.experiences;
      } catch (error) {
        this.error = error.message || "Fetching destinations failed!";
        this.showErrorComponent = true;
        console.error("error", this.error);
      }
      this.isLoading = false;
    },
    handleFilterButtonPressed(value) {
      const experienceCategory = this.categoryList.find(category => category.id === value);
      const paramValue = encodeURIComponent(experienceCategory.categoryName);

      router.push({
        path: `/experiences/${paramValue}/${experienceCategory.id}`,
        name: "Experience",
        params: {
          experienceType: paramValue,
          categoryId: experienceCategory.id
        }
      });
    },
    detectMob() {
      if (window.innerWidth <= 599) {
        // this.cardsPerPage = 6;
        this.isMobile = true;
      }
    },
    searchDestinations(searchTerm) {
      this.searchTerm = searchTerm;
      router.push({ path: "destinations", query: { searchTerm } });
    },
    onItemSelect(event) {
      router.push({ name: "DestinationPage", params: { destinationId: event.id } });
    },
    async getPopularExperiences() {
      try {
        const response = await experienceService.getExperienceList({
          itemsPerPage: this.carouselItemCount,
          page: 1
        });
        this.experiencePlaces = response.data;
      } catch (error) {
        console.error(error);
        this.showErrorComponent = true;
      } finally {
        this.isLoading = false;
      }
    },
    async getDestinationsList() {
      try {
        const filters = { page: 1, itemsPerPage: this.carouselItemCount };
        const destinationResponse = await destinationService.getAllDestinationsPaginated(filters);
        this.destinationPlaces = destinationResponse.data;
      } catch (error) {
        console.error(error);
        this.showErrorComponent = true;
      } finally {
        this.isLoading = false;
      }
    },
    async getExperienceCategories() {
      try {
        const categories = await categoryService.getCategories({
          itemCount: this.carouselItemCount
        });
        this.categoryList = categories;
      } catch (error) {
        console.error(error);
        this.showErrorComponent = true;
      } finally {
        this.isLoading = false;
      }
    },
    async getTravelerReviewList() {
      try {
        const reviews = await reviewService.getTravelerReviewList({
          itemCount: this.carouselItemCount
        });
        this.reviews = reviews;
      } catch (error) {
        console.error(error);
        this.showErrorComponent = true;
      } finally {
        this.isLoading = false;
      }
    },
    isShowing(isVisible, entry) {
      this.isVisible = isVisible;
      console.log(entry, isVisible);
      this.isActive = true;
    },
    setTimerForLoader() {
      setTimeout(() => {
        this.showLoader = false;
      }, 1000);
    }
  }
};
</script>

<template>
  <div>
    <PreLoader class="zIndexOverlay" v-show="showLoader"></PreLoader>
    <CommonLayout v-show="!showLoader">
      <div class="home">
        <div class="content-wrapper">
          <div class="header">
            <div class="header-text animate__animated animate__fadeInDown animate__slow 2s">
              <p class="web-header-text">{{ $t("homePage.homeBannerTextOne") }}</p>
              <p class="web-header-text">{{ $t("homePage.homeBannerTextTwo") }}</p>
              <p class="tab-mobile-header-text">{{ $t("homePage.mobileBannerTextOne") }}</p>
              <p class="tab-mobile-header-text">{{ $t("homePage.mobileBannerTextTwo") }}</p>
            </div>
            <center>
              <div>
                <div
                  v-if="isMobile"
                  class="mobile-btn-container animate__animated animate__fadeInUp animate__slow"
                >
                  <div>
                    <router-link to="/destinations">
                      <app-button
                        title="Discover Destinations"
                        class="mobile-header-btn"
                        :iconPath="destinationsIconPath"
                        iconShow="true"
                      />
                    </router-link>
                  </div>
                  <div>
                    <router-link to="/experiences/popular">
                      <app-button
                        title="Popular Experiences"
                        class="mobile-header-btn"
                        :iconPath="experiencesIconPath"
                        iconShow="true"
                      />
                    </router-link>
                  </div>
                </div>
                <div v-else class="search  animate__animated animate__fadeInUp animate__slow">
                  <Search
                    @on-item-selected="
                      dropdownSelection = $event;
                      onItemSelect($event);
                    "
                    @on-item-reset="dropdownSelection = {}"
                    @searchDestination="searchDestinations($event)"
                  />
                </div>
              </div>
            </center>
            <div class="contact contact-link animate__animated animate__fadeInUp animate__slow">
              {{ $t("homePage.contact") }}
              <router-link class="nav-sub-txt" to="/contact_us">
                {{ $t("homePage.contactUs") }}</router-link
              >
            </div>
            <!-- Scrolling animantion -->
            <a href="#destinationSection">
              <div
                class="scolling-button animate__animated animate__fadeInUp animate__slow"
                @click="clickOnScrolleButton"
              ></div>
            </a>
          </div>
          <div class="content">
            <!-- Destinations section -->
            <div class="destinations-section">
              <div class="heading-container">
                <!-- <div class="arrow-container"> -->
                <div class="page-title">
                  {{ $t("homePage.discoverTitle") }}
                </div>

                <div class="see-all">
                  <router-link class="nav-sub-txt" to="/destinations">
                    {{ $t("homePage.seeAll") }}</router-link
                  >
                </div>
              </div>
              <div class="horizontal-view-wrapper">
                <div v-if="showErrorComponent" class="home-error-text">
                  <span>{{ $t("api-error.error-message") }}</span>
                </div>
                <vue-horizontal
                  ref="horizontal"
                  class="horizontal horizontal-view"
                  :button="isMobile ? false : true"
                  v-else
                >
                  <LandscapeCard
                    id="landscape-card"
                    :isDestination="true"
                    v-for="(place, index) in destinationPlaces"
                    :key="index"
                    :cardData="place"
                    :dragged="dragged"
                  />
                </vue-horizontal>
              </div>
            </div>
            <!-- Experiences section -->
            <div class="heading-container">
              <div class="page-title">
                {{ $t("homePage.experiences-title") }}
              </div>
            </div>
            <div v-if="showErrorComponent" class="home-error-text">
              <span>{{ $t("api-error.error-message") }}</span>
            </div>
            <div
              style="margin-right: 0px"
              class="home-slider-left-2"
              v-observe-visibility="isShowing"
              v-else
            >
              <div class="horizontal-view-wrapper">
                <vue-horizontal
                  ref="horizontal"
                  class="horizontal horizontal-view"
                  :button="isMobile ? false : true"
                >
                  <PortraitCard
                    id="portrait-card"
                    :isCategory="true"
                    v-for="category in categoryList"
                    :key="category.id"
                    :cardData="category"
                    :dragged="dragged"
                  />
                </vue-horizontal>
              </div>
            </div>

            <!--  popular section -->
            <div class="heading-container">
              <div class="page-title">
                {{ $t("homePage.popular-experience-title") }}
              </div>
              <div class="see-all">
                <router-link class="nav-sub-txt" to="/experiences/popular">
                  {{ $t("homePage.seeAll") }}</router-link
                >
              </div>
            </div>
            <div style="margin-right: 0px">
              <div class="horizontal-view-wrapper">
                <div v-if="showErrorComponent" class="home-error-text home-error-bottom-margin">
                  <span>{{ $t("api-error.error-message") }}</span>
                </div>
                <vue-horizontal
                  ref="horizontal"
                  class="horizontal horizontal-view"
                  :button="isMobile ? false : true"
                  v-else
                >
                  <PortraitCard
                    id="portrait-card"
                    v-for="place in experiencePlaces"
                    :isExperience="true"
                    :key="place.id"
                    :cardData="place"
                    :dragged="dragged"
                  />
                </vue-horizontal>
              </div>
            </div>
          </div>
          <!-- Review section -->
          <div class="reviews">
            <div class="margin">
              <div class="review-title">
                <p>{{ $t("homePage.travel-review") }}</p>
              </div>
              <div class="horizontal-view-wrapper">
                <div v-if="showErrorComponent" class="home-error-text home-error-bottom-margin">
                  <span>{{ $t("api-error.error-message") }}</span>
                </div>
                <vue-horizontal
                  ref="horizontal"
                  class="horizontal horizontal-view"
                  :button="isMobile ? false : true"
                  v-else
                >
                  <ReviewCard
                    class="review-card"
                    v-for="review in reviews"
                    :key="review.id"
                    :cardData="review"
                  />
                </vue-horizontal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonLayout>
  </div>
</template>

<style lang="scss">
@import "/src/styles/views/_home.scss";
</style>
