<script>
// import * as services from '@/services/index';
import axios from "axios";
import * as destinationService from "@/api/services/destination.service";
import { KeyCodes } from "@/enums/common-enums";

export default {
  data() {
    return {
      selectedItem: {},
      inputValue: "",
      itemList: [],
      apiLoaded: false,
      apiUrl: "https://restcountries.eu/rest/v2/all?fields=name;flag"
    };
  },
  mounted() {
    // this.getList();
  },
  methods: {
    resetSelection() {
      this.selectedItem = {};
      this.$nextTick(() => this.$refs.dropdowninput.focus());
      this.$emit("on-item-reset");
    },
    selectItem(theItem) {
      this.selectedItem = theItem;
      this.inputValue = "";
      this.$emit("on-item-selected", theItem);
    },
    searchDestination() {
      this.itemList = [];
      this.$emit("searchDestination", this.inputValue);
    },
    itemVisible(item) {
      const currentName = item.destinationTitle.toLowerCase();
      const currentInput = this.inputValue.toLowerCase();
      return currentName.includes(currentInput);
    },
    async getList() {
      const searchTerm = this.inputValue;
      try {
        const destinationNamesList = await destinationService.getDestinationNamesList(searchTerm);
        this.itemList = destinationNamesList;
        this.apiLoaded = true;
      } catch (error) {
        this.error = error.message || "Fetching destination suggestions failed!";
        console.error("Error: ", this.error);
      }
    },
    async searchItem(keyEvent) {
      if (keyEvent.keyCode === KeyCodes.KEY_ENTER) {
        return;
      }
      this.getList();
    }
  }
};
</script>

<template>
  <div class="dropdown">
    <input
      v-if="Object.keys(selectedItem).length === 0"
      ref="dropdowninput"
      v-model.trim="inputValue"
      class="dropdown-input"
      type="text"
      placeholder="Search by destination"
      @keyup.enter="searchDestination()"
      @keyup="searchItem($event)"
    />
    <div v-else @click="resetSelection" class="dropdown-selected">
      {{ selectedItem.destinationTitle }}
    </div>
    <span class="search-icon">
      <i class="fas fa-search"></i>
    </span>
    <div v-show="inputValue && apiLoaded" class="dropdown-list">
      <div
        @click="selectItem(item)"
        v-show="itemVisible(item)"
        v-for="item in itemList"
        :key="item.id"
        class="dropdown-item"
      >
        {{ item.destinationTitle }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/styles/components/_search.scss";
</style>
