var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-container",on:{"click":function($event){return _vm.onCardPress(_vm.dragged, 1)}}},[(_vm.isCategory)?_c('div',{staticClass:"card-container-background",style:({
      position: _vm.absolute,
      top: 0,
      zIndex: -1,
      backgroundImage: ("linear-gradient(\n    2.51deg,\n    rgba(0, 0, 0, 0.5) 2.02%,\n    rgba(2, 20, 31, 0.5) 27.4%,\n    rgba(203, 227, 241, 0.118196) 62.57%,\n    rgba(255, 255, 255, 0) 95.71%\n  ), url('" + (_vm.cardData.categoryImage) + "')")
    })}):_c('div',{staticClass:"card-container-background",style:({
      backgroundImage: ("linear-gradient(\n    2.51deg,\n    rgba(0, 0, 0, 0.5) 2.02%,\n    rgba(2, 20, 31, 0.5) 27.4%,\n    rgba(203, 227, 241, 0.118196) 62.57%,\n    rgba(255, 255, 255, 0) 95.71%\n  ), url('" + (_vm.cardData.experienceImages[0]) + "')")
    })}),(_vm.isCategory)?_c('div',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.cardData.categoryName)+" ")]):_c('div',{staticClass:"card-title"},[_c('span',[_vm._v(" "+_vm._s(_vm.cardData.experienceTitle)+" ")]),_c('div',{staticClass:"card-location-row"},[_c('i',{staticClass:"fas fa-map-marker-alt location-icon"}),_c('div',{staticClass:"card-location-txt"},[_vm._v(" "+_vm._s(_vm.cardData && _vm.cardData.experienceDestination ? _vm.cardData.experienceDestination.destinationTitle : null)+" ")])])]),(this.showLoader)?_c('vue-content-loading',{staticClass:"loading-component-portrait",attrs:{"width":250,"height":450,"secondary":"#efefef"}},[_c('rect',{attrs:{"x":"0","y":"0","rx":"27","ry":"27","width":"250","height":"320"}}),_c('rect',{attrs:{"x":"10","y":"330","width":"230","height":"12"}}),_c('rect',{attrs:{"x":"10","y":"348","width":"215","height":"8"}})]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }