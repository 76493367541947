<script>
import FilterButton from "@/components/FilterButton.vue";
import AppButton from "@/components/AppButton.vue";

export default {
  name: "BoostapModal",
  props: {
    locations: {
      type: Array
    },
    experienceTypes: {
      type: Array
    },
    showExperienceType: {
      type: Boolean,
      default: false
    },
    showLocations: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FilterButton,
    AppButton
  },
  data() {
    return {
      locationViewMoreActivated: false,
      experienceViewMoreActivated: false,
      viewLimit: 2,
      modalVisible: false
    };
  },
  methods: {
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["filter-modal"].toggle("#toggle-btn");
      this.modalVisible = !this.modalVisible;
      if (this.modalVisible) {
        this.checkViewMore();
      }
    },
    handleLocationFilterButtonPressed(value) {
      this.$emit("onLocationFilterButtonPressed", value);
    },
    handleTypeFilterButtonPressed(value) {
      this.$emit("onTypeFilterButtonPressed", value);
    },
    handleApplyButtonPressed() {
      const filters = this.getFilterValues();
      this.$emit("onApplyButtonPressed", filters);
      this.toggleModal();
    },
    handleResetButtonPressed() {
      this.$emit("onResetButtonPressed");
    },
    getFilterValues() {
      let filters = {};
      if (this.showLocations) {
        filters = {
          destinations: this.locations
            .filter(location => location.selected)
            .map(location => location.destinationId)
        };
      }
      if (this.showExperienceType) {
        filters.experienceCategories = this.experienceTypes
          .filter(experienceCategory => experienceCategory.selected)
          .map(experienceCategory => experienceCategory.categoryId);
      }
      return filters;
    },
    activateExperienceViewMore() {
      this.experienceViewMoreActivated = true;
    },
    activateLocationViewMore() {
      this.locationViewMoreActivated = true;
    },
    activateLocationViewLess() {
      this.locationViewMoreActivated = false;
    },
    checkViewMore() {
      if (this.experienceTypes.length < this.viewLimit) {
        this.experienceViewMoreActivated = true;
      }
      if (this.locations.length < this.viewLimit) {
        this.locationViewMoreActivated = true;
      }
    }
  }
};
</script>

<template>
  <div>
    <div id="toggle-btn" class="btn-filter-open" @click="toggleModal">
      <i class="fas fa-sliders-h"></i>
    </div>
    <b-modal
      ref="filter-modal"
      id="modal-center"
      centered
      hide-header
      hide-footer
      title="BootstrapVue"
    >
      <div class="modal-container">
        <div class="modal-container-header">
          <div class="modal-txt-heading"><em class="fas fa-sliders-h fa-sm mr-2"/>{{ $t("filterModal.heading") }}</div>
          <div @click="toggleModal"><i class="fas fa-times modal-icon-close"></i></div>
        </div>
        <div v-if="showExperienceType" class="modal-sub-container">
          <div class="modal-sub-heading">{{ $t("filterModal.sub-heading-1") }}</div>
          <div class="modal-btn-container-wrapper" v-if="!experienceViewMoreActivated">
            <div class="modal-btn-container">
              <FilterButton
                id="modal-filter-button"
                v-for="experienceType in experienceTypes"
                :key="experienceType.categoryId"
                @onFilterButtonPressed="handleTypeFilterButtonPressed(experienceType)"
                :title="experienceType.type"
                :selected="experienceType.selected"
              />
            </div>
          </div>
          <!-- <div class="modal-btn-container" v-if="experienceViewMoreActivated">
            <FilterButton
              id="modal-filter-button"
              v-for="experienceType in experienceTypes"
              :key="experienceType.categoryId"
              @onFilterButtonPressed="handleTypeFilterButtonPressed(experienceType)"
              :title="experienceType.type"
              :selected="experienceType.selected"
            />
          </div> -->
        </div>
        <div v-if="showLocations" class="modal-sub-container">
          <div class="modal-sub-heading">{{ $t("filterModal.sub-heading-2") }}</div>
          <div class="modal-btn-container-wrapper" v-if="!locationViewMoreActivated">
            <div class="modal-btn-container">
              <FilterButton
                id="modal-filter-button"
                v-for="locationObject in locations.slice(0, this.viewLimit)"
                :key="locationObject.destinationId"
                @onFilterButtonPressed="handleLocationFilterButtonPressed(locationObject)"
                :title="locationObject.location"
                :selected="locationObject.selected"
              />
            </div>
            <a
              class="modal-btn-more"
              v-if="!locationViewMoreActivated"
              @click="activateLocationViewMore"
              href="#"
            >
              See more...
            </a>
          </div>
          <div class="modal-btn-container-with-scroll" v-if="locationViewMoreActivated">
            <FilterButton
              id="modal-filter-button"
              v-for="locationObject in locations"
              :key="locationObject.destinationId"
              @onFilterButtonPressed="handleLocationFilterButtonPressed(locationObject)"
              :title="locationObject.location"
              :selected="locationObject.selected"
            />
          </div>
          <a
            class="modal-btn-more"
            v-if="locationViewMoreActivated"
            @click="activateLocationViewLess"
            href="#"
          >
            See less...
          </a>
        </div>
        <div class="modal-primary-btn-container">
          <AppButton
            :title="$t('filterModal.btn-secondary')"
            class="secondary modal-btns-container"
            @onButtonPressed="handleResetButtonPressed"
          />
          <AppButton
            :title="$t('filterModal.btn-primary')"
            class="primary modal-btns-container"
            @onButtonPressed="handleApplyButtonPressed"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<style lang="scss">
@import "/src/styles/components/_bootstrap-modal.scss";
</style>
