import { ApiCall } from "../apiBase";

export async function getAllCategories() {
  let categories = [];

  const result = await ApiCall({
    method: "post",
    data: {
      query: `
            query getExperienceCategoryList {
                getExperienceCategoryList {
                    id
                    categoryName
                    categoryImage
                }
            }
            `
    }
  });

  if (!result.data.errors && result.data.data.getExperienceCategoryList) {
    categories = result.data.data.getExperienceCategoryList;
  }
  else {
    throw Error(result?.data?.errors[0]);
  }

  return categories;
}

export async function getCategories(filters) {
  let categories = [];

  const { itemCount } = filters || {};

  const result = await ApiCall({
    method: "post",
    data: {
      query: `
            query Query($getExperienceCategoryListItemCount: Int) {
              getExperienceCategoryList(itemCount: $getExperienceCategoryListItemCount) {
                id
                categoryName
                categoryImage
              }
            }
            `,
      variables: {
        getExperienceCategoryListItemCount: itemCount
      }
    }
  });

  if (!result.data.errors && result.data.data.getExperienceCategoryList) {
    categories = result.data.data.getExperienceCategoryList;
  }
  else {
    throw Error(result?.data?.errors[0]);
  }

  return categories;
}

export async function getCategoryById(categoryId) {
  let category = null;

  const result = await ApiCall({
    method: "post",
    data: {
      query: `
            query Query($getExperienceCategoryById: ID!) {
              getExperienceCategoryById(id: $getExperienceCategoryById) {
                id
                categoryImage
                categoryName
              }
            }
            `,
      variables: {
        getExperienceCategoryById: categoryId
      }
    }
  });

  if (!result.data.errors && result.data.data.getExperienceCategoryById) {
    category = result.data.data.getExperienceCategoryById;
  }
  else {
    throw Error(result?.data?.errors[0]);
  }

  return category;
}
