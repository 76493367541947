<script>
const DEFAULT_TRANSITION = "fade";

export default {
  name: "App",
  data() {
    return {
      prevHeight: 0,
      transitionName: DEFAULT_TRANSITION
    };
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      let transitionName = to.meta.transitionName || from.meta.transitionName;

      if (transitionName === "slide") {
        const toDepth = to.path.split("/").length;
        const fromDepth = from.path.split("/").length;
        transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
      }

      this.transitionName = transitionName || DEFAULT_TRANSITION;

      next();
    });
  }
};
</script>
<template>
  <div id="app">
    <transition :name="transitionName" mode="out-in" @beforeLeave="beforeLeave" @enter="enter">
      <router-view />
    </transition>
  </div>
</template>

<style lang="scss">
@import "./styles/style.scss";

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

// slide
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}

// fade
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.formulate-input[data-classification='text'] input {
  background: $white !important;
  &:focus {
      border: 1px solid #E5E5E5 !important;
  }
}

.formulate-input[data-classification='select'] select {
  background: $white !important;
  &:focus {
      border: 1px solid #E5E5E5 !important;
  }
}

.formulate-input-error {
  font-size: 11px !important;
}

.booking-datepicker input {
  width: 100%;
  display: block;
  padding: 0.75em;
  border-radius: 0.3em;
  border: 1px solid #cecece !important;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 0.9em;
  line-height: 1.2em;
  margin-bottom: 1.5em;
  &:focus-visible {
    border: 1px solid #cecece !important;
  }
}

.formulate-input[data-classification='select'] select[data-placeholder-selected] {
  font-style: italic;
}


.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  border: 1px solid $primary !important;
}

.vdp-datepicker__calendar .cell.selected {
  background: $primary !important;
  &:hover {
    background: $primary !important;
  }
}

.rtl {
  direction: rtl;
}

.vdp-datepicker {
  position: relative;
  text-align: left;
  box-sizing: border-box;

  .calendar-btn-disabled {
    cursor: not-allowed;
  }
}

.vdp-datepicker__calendar {
  background: #fff;
  border: 1px solid #ccc;
  position: absolute;
  width: 300px;
  z-index: 10000;

  .today {
    background-color: #eee;
  }

  * {
    box-sizing: border-box;
  }

  &.inline {
    position: static;
  }

  header {
    display: block;
    line-height: 40px;

    span {
      display: inline-block;
      float: left;
      text-align: center;
      width: 71.42857142857143%;
    }

    .prev,
    .next {
      float: left;
      max-height: 40px;
      position: relative;
      width: 14.285714285714286%;

      .default {
        text-indent: -10000px;

        &:after {
          border: 6px solid transparent;
          content: '';
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }

    .prev {
      .default {
        &:after {
          border-right: 10px solid #000;
          margin-left: -5px;
        }

        &.disabled:after {
          border-right: 10px solid #ddd;
        }
      }
    }

    .next {
      .default {
        &:after {
          border-left: 10px solid #000;
          margin-left: 5px;
        }

        &.disabled:after {
          border-left: 10px solid #ddd;
        }
      }
    }

    .prev:not(.disabled),
    .next:not(.disabled),
    .up:not(.disabled) {
      cursor: pointer;

      &:hover {
        background: #eee;
      }
    }
  }

  .disabled {
    color: #ddd;
    cursor: default;

    //&.selected {
    //  color: #104756;
    //}
  }

  .flex-rtl {
    display: flex;
    flex-wrap: wrap;
    width: inherit;
  }

  .cell {
    border: 1px solid transparent;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 5px;
    text-align: center;
    vertical-align: middle;
    width: 14.285714285714286%;

    &:not(.blank):not(.disabled).day,
    &:not(.blank):not(.disabled).month,
    &:not(.blank):not(.disabled).year {
      cursor: pointer;

      &:hover {
        border: 1px solid #4bd;
      }
    }

    &.selected {
      background: #4bd;
      color: #104756;

      &:hover {
        background: #4bd;
      }

      &.highlighted {
        background: #4bd;
      }
    }

    &.highlighted {
      background: #cae5ed;
      color: #104756;

      &.disabled {
        color: #accad2;
      }
    }

    &.muted {
      color: #757575;

      &.selected {
        color: #104756;
      }

      &.disabled:not(.selected) {
        color: #ddd;

        &.highlighted {
          color: #accad2;
        }
      }
    }
  }

  .day-header {
    display: inline-block;
    font-size: 75%;
    height: 40px;
    line-height: 40px;
    padding: 0 5px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    width: 14.285714285714286%;
  }

  .month,
  .year {
    width: 33.333%;
  }

  .picker-view {
    width: inherit;
  }
}

.vdp-datepicker__clear-button,
.vdp-datepicker__calendar-button {
  cursor: pointer;
  font-style: normal;

  &.disabled {
    color: #999;
    cursor: default;
  }
}
</style>
