import { ApiCall } from "../apiBase";

export async function getExperienceList(filters) {
  let experienceList = [];

  const { categoryId, destinationId, itemsPerPage, page } = filters || {};

  const result = await ApiCall({
    method: "post",
    data: {
      query: `
            query Query($getExperienceListPaginatedExperienceFilters: PopularExperienceFilters) {
                getExperienceListPaginated(experienceFilters: $getExperienceListPaginatedExperienceFilters) {
                    data {
                      id
                    experienceTitle
                    experienceDestination {
                        id
                        destinationTitle
                    }
                    experienceCategories {
                      id
                      categoryName
                      categoryImage
                    }
                    experienceImages
                    }
                    count
                }
            }
            `,
      variables: {
        getExperienceListPaginatedExperienceFilters: {
          categoryId,
          destinationId,
          itemsPerPage,
          page
        }
      }
    }
  });

  if (!result.data.errors && result.data.data.getExperienceListPaginated) {
    experienceList = result.data.data.getExperienceListPaginated;
  } else {
    throw Error(result?.data?.errors[0]);
  }

  return experienceList;
}

export async function getExperiencesByCategory(categoryId, page, itemsPerPage) {
  let experienceList = [];

  const result = await ApiCall({
    method: "post",
    data: {
      query: `
           query Query($getExperienceByCategoryExperienceFilters: ExperienceFilters!) {
                getExperienceByCategory(experienceFilters: $getExperienceByCategoryExperienceFilters) {
                    data {
                    experienceTitle
                    id
                    experienceCategories {
                        categoryImage
                        categoryName
                        id
                    }
                    experienceDestination {
                        id
                        destinationTitle
                    }
                    experienceImages
                    }
                    count
                }
            }
            `,
      variables: {
        getExperienceByCategoryExperienceFilters: {
          itemsPerPage: itemsPerPage,
          page: page,
          categoryId: categoryId
        }
      }
    }
  });

  if (!result.data.errors && result.data.data.getExperienceByCategory) {
    experienceList = result.data.data.getExperienceByCategory;
  } else {
    throw Error(result?.data?.errors[0]);
  }

  return experienceList;
}

export async function getExperiencesById(experienceId) {
  const result = await ApiCall({
    method: "post",
    data: {
      query: `
           query Query($getExperienceById: ID!) {
              getExperienceById(id: $getExperienceById) {
                id
                experienceTitle
                experienceDescription
                experienceTier
                experienceImages
                experiencePrice
                latitude
                longitude
                experienceSessionStartTimes
                experienceDestination {
                  destinationTitle
                  id
                }
                experienceKeyInfo {
                  id
                  keyInfoName
                  keyInfoIcon
                }
                experienceAvailableTime {
                  weekdays
                  weekend
                }
                reviews {
                  id
                  reviewerName
                  review
                  rating
                  reviewerImage
                }
              }
            }
            `,
      variables: {
        getExperienceById: experienceId
      }
    }
  });

  if (!result.data.errors && result.data.data.getExperienceById) {
    return result.data.data.getExperienceById;
  } else {
    throw Error(result?.data?.errors[0]);
  }
}
