<script>
import PortraitCard from "@/components/PortraitCard.vue";
import Pagination from "@/components/Pagination.vue";
import BootstrapModal from "@/components/BootstrapModal.vue";
import CommonLayout from "@/views/CommonLayout.vue";
import ErrorComponent from "@/components/APIError.vue";
import EmptyDataContainer from "../components/EmptyDataContainer.vue";

import * as categoryService from "@/api/services/category.service";
import * as experienceService from "@/api/services/experience.service";
import * as destinationService from "@/api/services/destination.service";

export default {
  name: "Home",
  components: {
    PortraitCard,
    Pagination,
    BootstrapModal,
    CommonLayout,
    ErrorComponent,
    EmptyDataContainer
  },
  data() {
    return {
      isLoading: true,
      error: null,
      experiencePlaces: [],
      currentPage: 1,
      isModalVisible: false,
      locations: [],
      cardsPerPage: 15,
      show: false,
      totalPages: 0,
      title: null,
      experienceCategoryId: null,
      selectedDestination: null,
      experienceCategory: null,
      showErrorComponent: false
    };
  },
  computed: {
    checkLoading() {
      return this.isLoading;
    },
    checkDataAvailable() {
      if (this.experiencePlaces.length > 0) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    this.detectMob();
    this.getExperienceCategory();
    this.getExperiences();
    this.getLocations();
  },
  methods: {
    async getExperienceCategory() {
      try {
        const categoryId = this.$route.params.categoryId;
        this.experienceCategory = await categoryService.getCategoryById(categoryId);
      } catch (error) {
        console.error("error", this.error);
      }
    },
    async getExperiences() {
      try {
        this.isLoading = true;
        this.experienceCategoryId = this.$route.params.categoryId;

        const response = await experienceService.getExperienceList({
          categoryId: [this.experienceCategoryId],
          destinationId: this.selectedDestination,
          itemsPerPage: this.cardsPerPage,
          page: this.currentPage
        });

        this.experiencePlaces = response.data;
        this.totalPages = Math.ceil(response.count / this.cardsPerPage);
      } catch (error) {
        this.showErrorComponent = true;
      } finally {
        this.isLoading = false;
      }
    },
    async getLocations() {
      try {
        const response = await destinationService.getAllDestinations();

        if (response.length > 0) {
          this.locations = response.map(destination => ({
            location: destination.destinationTitle,
            destinationId: destination.id,
            selected: false
          }));
        }
      } catch (error) {
        this.error = error.message || "Fetching Locations failed!";
        console.error("error", this.error);
      }
    },
    onPageChange(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        this.getExperiences();
        window.scrollTo({ top: 0, left: 0 });
      }
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    handleFilterButtonPressed(value) {
      this.locations = this.locations.map(location => {
        if (location.destinationId === value.destinationId) {
          return {
            location: location.location,
            selected: !location.selected,
            destinationId: location.destinationId
          };
        }

        return location;
      });
    },
    handleApplyButtonPressed(filters) {
      this.selectedDestination =
        filters.destinations && filters.destinations.length > 0 ? filters.destinations : null;

      this.getExperiences();
    },
    handleResetButtonPressed() {
      this.locations = this.locations.map(location => ({
        location: location.location,
        selected: false,
        destinationId: location.destinationId
      }));
    },
    detectMob() {
      if (window.innerWidth <= 1799) {
        this.cardsPerPage = 12;
      }
    }
  }
};
</script>

<template>
  <CommonLayout>
    <div v-if="this.showErrorComponent">
      <ErrorComponent />
    </div>
    <div v-else>
      <div class="content">
        <div class="heading-container">
          <div class="page-title">{{ experienceCategory.categoryName }}</div>
          <BootstrapModal
            :locations="locations"
            showLocations
            @onApplyButtonPressed="handleApplyButtonPressed"
            @onResetButtonPressed="handleResetButtonPressed"
            @onLocationFilterButtonPressed="handleFilterButtonPressed"
          />
        </div>
        <div v-if="checkLoading" class="loading-container">
          <b-spinner label="Loading..." variant="secondary"></b-spinner>
        </div>
        <div class="card-outer-container-wrapper " v-else-if="checkDataAvailable">
          <div class="card-outer-container">
            <PortraitCard
              id="portrait-card"
              v-for="place in experiencePlaces"
              :key="place.id"
              :cardData="place"
              :isExperience="true"
            />
          </div>
          <div class="pagination-container">
            <div class="pagination-wrapper">
              <Pagination
                :total-pages="this.totalPages"
                :per-page="cardsPerPage"
                :current-page="currentPage"
                @pagechanged="onPageChange"
              />
            </div>
          </div>
        </div>
        <div v-else class="empty-container margin">
          <empty-data-container noExperience :description="this.$t('experience-category.error')" />
        </div>
      </div>
    </div>
  </CommonLayout>
</template>

<style lang="scss">
@import "/src/styles/views/_experience-category.scss";

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
