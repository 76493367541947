import { render, staticRenderFns } from "./LandscapeCard.vue?vue&type=template&id=fdc58664&scoped=true&"
import script from "./LandscapeCard.vue?vue&type=script&lang=js&"
export * from "./LandscapeCard.vue?vue&type=script&lang=js&"
import style0 from "./LandscapeCard.vue?vue&type=style&index=0&id=fdc58664&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdc58664",
  null
  
)

export default component.exports