<template>
  <CommonLayout>
    <div v-if="this.showErrorComponent">
      <ErrorComponent />
    </div>
    <div v-else>
      <div class="content">
        <div class="heading-container animate__animated animate__fadeInDown">
          <div class="page-title">
            {{ $t("popular-experiences.page-title") }}
          </div>
          <BootstrapModal
            :locations="locations"
            :experienceTypes="experienceTypes"
            showLocations
            showExperienceType
            @onApplyButtonPressed="handleApplyButtonPressed"
            @onResetButtonPressed="handleResetButtonPressed"
            @onLocationFilterButtonPressed="handleLocationFilterButtonPressed"
            @onTypeFilterButtonPressed="handleTypeFilterButtonPressed"
          />
        </div>
        <div v-if="checkLoading" class="loading-container">
          <b-spinner label="Loading..." variant="secondary"></b-spinner>
        </div>
        <div
          class="card-outer-container-wrapper animate__animated animate__fadeIn animate__slower 2s"
          v-else-if="checkDataAvailable"
        >
          <div class="card-outer-container">
            <PortraitCard
              id="portrait-card"
              v-for="place in experiencePlaces"
              :key="place.id"
              :cardData="place"
              :isExperience="true"
            />
          </div>
          <div class="pagination-container">
            <div class="pagination-wrapper">
              <Pagination
                :total-pages="this.totalPages"
                :per-page="cardsPerPage"
                :current-page="currentPage"
                @pagechanged="onPageChange"
              />
            </div>
          </div>
        </div>
        <div v-else>
          <empty-data-container noExperience :description="this.$t('experience-category.error')" />
        </div>
      </div>
    </div>
  </CommonLayout>
</template>

<script>
import * as services from "@/services/index";
import PortraitCard from "@/components/PortraitCard.vue";
import Pagination from "@/components/Pagination.vue";
import BootstrapModal from "@/components/BootstrapModal.vue";
import CommonLayout from "@/views/CommonLayout.vue";

import * as categoryService from "@/api/services/category.service";
import * as experienceService from "@/api/services/experience.service";
import * as destinatinService from "@/api/services/destination.service";

import VueContentLoading from "vue-content-loading";
import EmptyDataContainer from "@/components/EmptyDataContainer.vue";
import ErrorComponent from "@/components/APIError.vue";


import { swapElement } from "../helpers/arrayReOrder";

export default {
  name: "Home",
  components: {
    PortraitCard,
    Pagination,
    BootstrapModal,
    CommonLayout,
    VueContentLoading,
    EmptyDataContainer,
    ErrorComponent
  },
  data() {
    return {
      isLoading: true,
      error: null,
      showErrorComponent: false,

      //the isLoading objects are used to render loading components
      experiencePlaces: [
        { isLoading: true },
        { isLoading: true },
        { isLoading: true },
        { isLoading: true },
        { isLoading: true },
        { isLoading: true },
        { isLoading: true },
        { isLoading: true }
      ],

      currentPage: 1,
      isModalVisible: false,
      locations: [],
      experienceTypes: [],
      cardsPerPage: 15,
      show: false,
      totalPages: 0,
      selectedDestination: null,
      selectedCategory: null
    };
  },
  computed: {
    checkLoading() {
      return this.isLoading;
    },
    checkDataAvailable() {
      if (this.experiencePlaces.length > 0) {
        console.log(this.experiencePlaces.length);
        return true;
      }
      return false;
    }
  },
  mounted() {
    this.detectMob();
    this.getLocations();
    this.getExperienceTypes();
    this.getExperiences();
    this.checkReviews();
  },
  beforeMount() {
    window.scrollTo({ top: 0, left: 0 });
  },
  methods: {
    async getExperiences() {
      this.isLoading = true;
      try {
        this.isLoading = true;

        const response = await experienceService.getExperienceList({
          categoryId: this.selectedCategory,
          destinationId: this.selectedDestination,
          itemsPerPage: this.cardsPerPage,
          page: this.currentPage
        });

        this.experiencePlaces = response.data;
        this.totalPages = Math.ceil(response.count / this.cardsPerPage);
      } catch (error) {
        this.showErrorComponent = true;
      }
      this.isLoading = false;
    },
    async getLocations() {
      try {
        const response = await destinatinService.getAllDestinations();

        if (response.length > 0) {
          this.locations = response.map(destination => ({
            location: destination.destinationTitle,
            destinationId: destination.id,
            selected: false
          }));
        }
      } catch (error) {
        this.error = error.message || "Fetching Locations failed!";
        console.error("error", this.error);
      }
    },
    async getExperienceTypes() {
      try {
        const response = await categoryService.getAllCategories();
        if (response.length > 0) {
          this.experienceTypes = response.map(type => ({
            type: type.categoryName,
            categoryId: type.id,
            selected: false
          }));
        }
        //array elements swapped in order to take up space in filter modal
        this.experienceTypes = swapElement(this.experienceTypes, 3, 5);
      } catch (error) {
        this.error = error.message || "Fetching Experience types failed!";
        console.error("error", this.error);
      }
    },
    onPageChange(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        this.getExperiences();
        window.scrollTo({ top: 0, left: 0 });
      }
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    handleLocationFilterButtonPressed(value) {
      this.locations = this.locations.map(location => {
        if (location.destinationId === value.destinationId) {
          return {
            location: location.location,
            selected: !location.selected,
            destinationId: location.destinationId
          };
        }

        return location;
      });
    },
    handleTypeFilterButtonPressed(value) {
      this.experienceTypes = this.experienceTypes.map(experience => {
        if (experience.categoryId === value.categoryId) {
          return {
            type: experience.type,
            selected: !experience.selected,
            categoryId: experience.categoryId
          };
        }

        return experience;
      });
    },
    handleApplyButtonPressed(filters) {
      this.selectedDestination =
        filters.destinations && filters.destinations.length > 0 ? filters.destinations : null;
      this.selectedCategory =
        filters.experienceCategories && filters.experienceCategories.length > 0
          ? filters.experienceCategories
          : null;
      this.getExperiences();
    },
    handleResetButtonPressed() {
      this.locations = this.locations.map(location => ({
        location: location.location,
        selected: false,
        destinationId: location.destinationId
      }));
      this.experienceTypes = this.experienceTypes.map(experience => ({
        type: experience.type,
        selected: false,
        categoryId: experience.categoryId
      }));
    },
    detectMob() {
      if (window.innerWidth < 1799) {
        this.cardsPerPage = 12;
        this.experiencePlaces = [
          { isLoading: true },
          { isLoading: true },
          { isLoading: true },
          { isLoading: true },
          { isLoading: true },
          { isLoading: true }
        ];
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "/src/styles/views/_popular-experience.scss";
</style>
