<script>
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import NavBar from "@/components/NavBar.vue";

export default {
  name: "CommonLayout",
  components: {
    NavBar,
    Footer,
    SideBar
  },
  mounted() {
    window.addEventListener("scroll", this.scrollEvent);
  }
};
</script>

<template>
  <div class="content-wrapper">
    <SideBar />
    <NavBar />
    <div v-scrollbar class="content-height">
      <slot> </slot>
    </div>
    <Footer />
  </div>
</template>

<style lang="scss">
@import "@/styles/views/_common-layout.scss";
</style>
