<script>
//importing different logos for both transparent navbar and solid navbar
import lightLogo from "@/assets/mainLogoWhiteFont.webp";
import darkLogo from "@/assets/mainLogoLightBackground.webp";

export default {
  name: "NavBar",

  //style objects for transparent navbar
  data: function() {
    return {
      //transpaernt background style object
      bgStyle: {
        type: Object,
        default: null
      },
      //white color font for transparent navbar
      fontStyle: {
        type: Object
      },
      //white color font logo for transparent navbar
      logoImg: {
        type: String,
        default: darkLogo
      },
      scrollPosition: 100
    };
  },

  methods: {
    openNav() {
      document.getElementById("app-side-nav").style.left = 0;
    },

    //method to change styling for navbar if on homepage
    transparentNav: function() {
      if (this.$route.path === "/") {
        this.bgStyle = {
          background: "transparent"
        };
        this.fontStyle = {
          color: "white"
        };
        this.logoImg = lightLogo;
      } else {
        return (this.logoImg = darkLogo);
      }
    },

    //method to transition transparent navbar into solid navbar after passing header image
    handleScroll() {
      let homeScrollDown = window.scrollY;
      if (this.$route.path === "/") {
        if (homeScrollDown >= this.scrollPosition) {
          this.bgStyle = {
            transition: "all 0.2s ease-in"
          };
          this.fontStyle = null;
          this.logoImg = darkLogo;
        } else {
          this.bgStyle = {
            background: "transparent",
            transition: "all 0.2s ease-in"
          };
          this.fontStyle = {
            color: "white"
          };
          this.logoImg = lightLogo;
        }
      }
    }
  },
  created() {
    this.transparentNav();
  },
  //calling method as soon as component mounts
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  }
};
</script>

<template>
  <header :style="bgStyle">
    <nav>
      <div class="settings-icon-container">
        <!-- <router-link to="/settings"> -->
        <div class="settings-icon-container" @click="openNav" :style="fontStyle">
          <i class="fas fa-bars settings-icon" :style="fontStyle"></i>
        </div>
        <!-- </router-link> -->
      </div>

      <div>
        <router-link to="/">
          <div class="image-class">
            <img alt="Logo" :src="logoImg" class="footer-container-top-logo" />
          </div>
        </router-link>
      </div>
      <div class="nav-sub-headings-container">
        <div class="nav-sub-headings-container-text">
          <div class="nav-sub-heading">
            <router-link class="nav-sub-txt" to="/" :style="fontStyle">Home</router-link>
          </div>
          <div class="nav-sub-heading">
            <router-link class="nav-sub-txt" to="/destinations" :style="fontStyle"
              >Destinations</router-link
            >
          </div>
          <div class="nav-sub-heading">
            <router-link class="nav-sub-txt" to="/experiences/popular" :style="fontStyle"
              >Experiences</router-link
            >
          </div>
          <div class="nav-sub-heading">
            <router-link class="nav-sub-txt" to="/contact_us" :style="fontStyle"
              >Contact</router-link
            >
          </div>
          <!--<div class="nav-sub-heading">
              <router-link class="nav-sub-txt" to="/faqs">FAQs</router-link>
            </div>-->
        </div>
        <div class="nav-sub-headings-container-icons">
          <div class="nav-sub-heading">
            <a
              class="nav-sub-txt"
              href="https://www.facebook.com/explorethelocal"
              target="blank"
              :style="fontStyle"
              ><i class="fab fa-facebook-f"></i
            ></a>
          </div>
          <div class="nav-sub-heading">
            <a
              class="nav-sub-txt"
              href="https://www.instagram.com/explore.the.local"
              target="_blank"
              :style="fontStyle"
              ><i class="fab fa-instagram mobile-padding-insta"></i
            ></a>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/components/nav-bar.scss";
</style>
