import axios from "axios";
import { API_BASE } from "./apiConfig";

export const ApiCall = (requestInfo, headers) => {
  //   if (!headers || headers === null) {
  //     headers = {};

  //     const token = window.localStorage.getItem("authUser");
  //     const tokenExpire = window.localStorage.getItem("tokenExpireDate");
  //     const tokenExpireJS = new Date(tokenExpire);
  //     const toDayDateJS = new Date();
  //     const tokenExpireJSConvert = tokenExpireJS.getTime();
  //     const toDayDateJSCovert = toDayDateJS.getTime();

  //     if (tokenExpire) {
  //       if (tokenExpireJSConvert < toDayDateJSCovert) {
  //         window.localStorage.removeItem("authUser");
  //         window.localStorage.removeItem("tokenExpireDate");
  //         window.location.href = "./login";
  //       }
  //     }

  //     if (token) {
  //       headers.Authorization = `JWT ${token}`;
  //     }
  //   }

  return axios({ ...requestInfo, url: API_BASE, headers });
};
