<script>
import router from "../router/";
import VueContentLoading from "vue-content-loading";

export default {
  name: "PortraitCard",
  data() {
    return {
      showLoader: true
    };
  },
  props: {
    dragged: {
      type: Boolean
    },
    cardData: {
      type: Object
    },
    isCategory: {
      type: Boolean,
      default: false
    },
    isExperience: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  },
  components: {
    VueContentLoading
  },
  methods: {
    onCardPress(dragged, id) {
      if (!dragged) {
        if (this.isCategory) {
          router.push({
            name: "ExperienceCategory",
            params: {
              categoryId: this.cardData.id
            }
          });
        } else if (this.isExperience) {
          router.push({
            name: "ExperienceDetail",
            params: {
              experienceId: this.cardData.id
            }
          });
        }
      }
    },
    setShowLoaderState() {
      setTimeout(() => {
        this.showLoader = false;
      }, 1000);
    }
  },
  mounted() {
    this.setShowLoaderState();
  }
};
</script>

<template>
  <div class="card-container" @click="onCardPress(dragged, 1)">
    <div
      v-if="isCategory"
      class="card-container-background"
      :style="{
        position: absolute,
        top: 0,
        zIndex: -1,
        backgroundImage: `linear-gradient(
      2.51deg,
      rgba(0, 0, 0, 0.5) 2.02%,
      rgba(2, 20, 31, 0.5) 27.4%,
      rgba(203, 227, 241, 0.118196) 62.57%,
      rgba(255, 255, 255, 0) 95.71%
    ), url('${cardData.categoryImage}')`
      }"
    ></div>
    <div
      v-else
      class="card-container-background"
      :style="{
        backgroundImage: `linear-gradient(
      2.51deg,
      rgba(0, 0, 0, 0.5) 2.02%,
      rgba(2, 20, 31, 0.5) 27.4%,
      rgba(203, 227, 241, 0.118196) 62.57%,
      rgba(255, 255, 255, 0) 95.71%
    ), url('${cardData.experienceImages[0]}')`
      }"
    ></div>
    <div class="card-title" v-if="isCategory">
      {{ cardData.categoryName }}
    </div>
    <div class="card-title" v-else>
      <span>
        {{ cardData.experienceTitle }}
      </span>
      <div class="card-location-row">
        <i class="fas fa-map-marker-alt location-icon"></i>
        <div class="card-location-txt">
          {{
            cardData && cardData.experienceDestination
              ? cardData.experienceDestination.destinationTitle
              : null
          }}
        </div>
      </div>
    </div>

    <!--loading component will be shown for a set time until the card component renders underneath-->
    <vue-content-loading
      :width="250"
      :height="450"
      secondary="#efefef"
      class="loading-component-portrait"
      v-if="this.showLoader"
    >
      <rect x="0" y="0" rx="27" ry="27" width="250" height="320" />
      <rect x="10" y="330" width="230" height="12" />
      <rect x="10" y="348" width="215" height="8" />
    </vue-content-loading>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/components/_portrait-card.scss";
</style>
