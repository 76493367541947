<script>
export default {
  name: "Footer",
  data() {
    return {
      isTab: false
    };
  },
  beforeMount() {
    this.checkTab();
  },
  methods: {
    //checks whether the device is a tab to change footer styling
    checkTab() {
      if (600 < window.innerWidth && window.innerWidth < 900) {
        this.isTab = true;
      }
    }
  }
};
</script>

<template>
  <div class="footer-container">
    <div class="footer-container-top">
      <div class="footer-container-top-icon-container">
        <img
          alt="Logo"
          src="../assets/mainLogoLightBackground.webp"
          class="footer-container-top-logo"
        />
      </div>
      <div class="footer-container-top-row">
        <div class="footer-container-top-description">
          <div class="footer-container-top-txt">
            {{ $t("footer.description") }}
          </div>
        </div>
        <div class="footer-container-top-separator" />
        <div class="tab-layout-footer-wrapper">
          <div class="footer-container-top-links">
            <div class="footer-container-top-links-left">
              <div class="footer-container-top-link-item">
                +
                <router-link class="footer-container-top-link-item link-style" to="/destinations">{{
                  $t("footer.discover-destinations")
                }}</router-link>
              </div>
              <div class="footer-container-top-link-item">
                +
                <router-link
                  class="footer-container-top-link-item link-style"
                  to="/experiences/popular"
                  >{{ $t("footer.popular-experiences") }}</router-link
                >
              </div>
              <div class="footer-container-top-link-item" v-show="!isTab">
                +
                <router-link
                  class="footer-container-top-link-item link-style"
                  to="/terms&conditions"
                  >{{ $t("footer.terms-conditions") }}</router-link
                >
              </div>
            </div>
            <div class="footer-container-top-links-right">
              <div class="footer-container-top-link-item">
                +
                <router-link class="footer-container-top-link-item link-style" to="/our_story">{{
                  $t("footer.our-story")
                }}</router-link>
              </div>
              <div class="footer-container-top-link-item">
                +
                <router-link class="footer-container-top-link-item link-style" to="/contact_us">{{
                  $t("footer.contact-us")
                }}</router-link>
              </div>
              <div class="footer-container-top-link-item">
                +
                <router-link
                  class="footer-container-top-link-item link-style"
                  to="/privacy_policy"
                  >{{ $t("footer.booking-policy") }}</router-link
                >
              </div>
              <div class="footer-container-top-link-item" v-show="isTab">
                +
                <router-link
                  class="footer-container-top-link-item link-style"
                  to="/terms&conditions"
                  >{{ $t("footer.terms-conditions") }}</router-link
                >
              </div>
            </div>
          </div>
          <div class="footer-container-top-contacts">
            <div class="footer-container-top-contacts-wrapper tab-layout-padding-left">
              <i class="fas fa-phone-alt footer-container-contact-icon"></i>
              <div>
                <div class="footer-container-top-contacts-hd">
                  {{ $t("footer.telephone") }}
                </div>
                <div class="footer-container-top-contacts-txt">
                  <a id="mail-link" :href="`tel:+94 77 1234 567`" style="color: #000">
                    (+94) 77 1234 567
                  </a>
                </div>
              </div>
            </div>
            <div class="footer-container-top-contacts-wrapper tab-layout-padding-left">
              <i class="fas fa-envelope footer-container-contact-icon"></i>
              <div>
                <div class="footer-container-top-contacts-hd">
                  {{ $t("footer.email") }}
                </div>
                <div class="footer-container-top-contacts-txt">
                  <a id="mail-link" :href="`mailto:info@explorethelocal.com`" style="color: #000"
                    >info@explorethelocal.com</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-container-bottom">
      <div class="bottom-container">
        <div class="footer-container-bottom-txt">
          {{ $t("footer.copyright-label") }}
          {{ $t("footer.dev-label-1") }}
          <i class="fas fa-heart footer-container-bottom-heart"></i>
          <a class="href-style-link" href="https://nichelabs.lk/" target="_blank">
            {{ $t("footer.dev-label-2") }}
          </a>
        </div>
        <div class="footer-container-bottom-icon">
          <a
            class="href-style-link"
            href="https://www.facebook.com/explorethelocal"
            target="_blank"
          >
            <i class="fab fa-facebook-f" />
          </a>
          <a
            class="href-style-link"
            href="https://www.instagram.com/explore.the.local"
            target="_blank"
          >
            <i class="fab fa-instagram footer-container-bottom-icon-insta" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/components/footer.scss";
</style>
