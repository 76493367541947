<script>
export default {
  name: "SideBar",
  methods: {
    closeNav() {
      document.getElementById("app-side-nav").style.left = "-100vw";
    }
  }
};
</script>

<template>
  <div id="app-side-nav" class="side-nav">
    <div class="side-nav-container">
      <div class="side-nav-top">
        <div class="side-nav-top-head">
          <div class="side-nav-top-head-icons">
            <div class="side-nav-heading">
              <a class="side-nav-txt" href="https://www.facebook.com/explorethelocal" target="blank"
                ><i class="fab fa-facebook-f"></i
              ></a>
            </div>
            <div class="side-nav-heading">
              <a
                class="side-nav-txt"
                href="https://www.instagram.com/explore.the.local"
                target="_blank"
                ><i class="fab fa-instagram"></i
              ></a>
            </div>
          </div>
          <div class="side-nav-top-head-logo">
            <div class="side-nav-image-class" @click="closeNav">
              <router-link to="/">
                <img
                  alt="Logo"
                  src="@/assets/mainLogoLightBackground.webp"
                  class="side-nav-top-logo"
                />
              </router-link>
            </div>
          </div>
          <div class="side-nav-top-head-close" @click="closeNav">
            <i class="fas fa-times side-nav-icon-close"></i>
          </div>
        </div>
        <div class="side-nav-top-links">
          <div class="side-nav-top-links-left">
            <div class="side-nav-top-link-item" @click="closeNav">
              +
              <router-link class="footer-container-top-link-item link-style" to="/destinations">{{
                $t("footer.discover-destinations")
              }}</router-link>
            </div>
            <div class="side-nav-top-link-item" @click="closeNav">
              +
              <router-link
                class="footer-container-top-link-item link-style"
                to="/experiences/popular"
                >{{ $t("footer.popular-experiences") }}</router-link
              >
            </div>
            <div class="side-nav-top-link-item" @click="closeNav">
              +
              <router-link
                class="footer-container-top-link-item link-style"
                to="/terms&conditions"
                >{{ $t("footer.terms-conditions") }}</router-link
              >
            </div>
            <div class="side-nav-top-link-item" @click="closeNav">
              +
              <router-link class="footer-container-top-link-item link-style" to="/privacy_policy">{{
                $t("footer.booking-policy")
              }}</router-link>
            </div>
          </div>
          <div class="side-nav-top-links-right">
            <div class="side-nav-top-link-item" @click="closeNav">
              +
              <router-link class="footer-container-top-link-item link-style" to="/our_story">{{
                $t("footer.our-story")
              }}</router-link>
            </div>
            <div class="side-nav-top-link-item" @click="closeNav">
              +
              <router-link class="footer-container-top-link-item link-style" to="/contact_us">{{
                $t("footer.contact-us")
              }}</router-link>
            </div>
          </div>
        </div>
        <div class="side-nav-top-txt">
          {{ $t("footer.description") }}
        </div>
      </div>
      <div class="side-nav-bottom">
        <div class="side-nav-bottom-txt" @click="closeNav">
          <a class="sidebar-nichelabs-link" href="https://nichelabs.lk/" target="_blank">
            Made with <i class="fas fa-heart side-nav-bottom-heart"></i> by Nichelabs
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/components/footer.scss";
@import "@/styles/components/side-bar.scss";
</style>
