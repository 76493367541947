import Vue from "vue";
import { BootstrapVue } from "bootstrap-vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import i18n from "./i18n";

import VueDragscroll from "vue-dragscroll";
import VueHorizontal from "vue-horizontal";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "animate.css";
import * as VueGoogleMaps from "vue2-google-maps";
import '../node_modules/@braid/vue-formulate/themes/snow/snow.scss';
import "../node_modules/@sum.cumo/vue-datepicker/src/styles/style.scss";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyD69G2nAbl3Gs8f_aGAHGMnng6QMv9sx0k",
    libraries: "places" // This is required if you use the Autocomplete plugin
  }
});

Vue.use(VueDragscroll);
Vue.use(BootstrapVue);
Vue.use(VueHorizontal);
Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
