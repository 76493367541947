<script>
export default {
  name: "EmptyDataContainer",
  props: {
    description: {
      type: String,
      default: "Oops! Sorry we could not find any data to display."
    },
    noExperience: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<template>
  <div class="empty-data-wrapper">
    <div class="image-container">
      <img alt="No data Image" src="../assets/no-data-found.webp" class="no-data-image" />
    </div>
    <span v-if="noExperience" class="description-text">
      {{ description }}<br />
      or
      <a href="/contact_us" class="hyperlink" target="_blank">contact us</a>
      for help
    </span>
    <span v-else class="description-text">
      {{ description }}<br />
      <a href="/contact_us" class="hyperlink" target="_blank">Contact us</a>
      if you need some help
    </span>
  </div>
</template>

<style scoped lang="scss">
@import "@/styles/components/_empty-data-container.scss";
</style>
