<script>
import router from "../router/index";
import VueContentLoading from "vue-content-loading";

export default {
  name: "LandscapeCard",
  data() {
    return {
      showLoader: true,
      cardContainerMargin: null,
      isMobile: false
    };
  },
  props: {
    dragged: {
      type: Boolean
    },
    cardData: {
      type: Object
    },
    isLoading: {
      type: Boolean,
      default: true
    },
    destinationsPage: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VueContentLoading
  },
  methods: {
    onCardPress(dragged, id) {
      if (!dragged) {
        router.push({ name: "DestinationPage", params: { destinationId: id } });
      }
    },
    setShowLoaderState() {
      setTimeout(() => {
        this.showLoader = false;
      }, 1000);
    },
    //checks whether the landscape card is being loaded on the destination page and adds the margin bottom
    checkDestinationPageMobile() {
      if (this.destinationsPage === true && window.innerWidth <= 767) {
        this.cardContainerMargin = {
          marginBottom: "25px !important"
        };
      }
    }
  },
  mounted() {
    this.setShowLoaderState();
    this.checkDestinationPageMobile();
  }
};
</script>

<template>
  <div
    class="card-container"
    @click="onCardPress(dragged, cardData.id)"
    :style="this.cardContainerMargin"
  >
    <div
      class="card-container-background"
      :style="{
        zIndex: -1,
        backgroundImage: `linear-gradient(
      2.51deg,
      rgba(0, 0, 0, 0.5) 2.02%,
      rgba(2, 20, 31, 0.5) 27.4%,
      rgba(203, 227, 241, 0.118196) 62.57%,
      rgba(255, 255, 255, 0) 95.71%
    ), url('${cardData.destinationImageList[0]}')`
      }"
    >
      <div class="card-title">
        <span>
          {{ cardData.destinationTitle }}
        </span>
        <div class="card-location-row">
          <div class="card-location-txt">
            {{ cardData.destinationProvince }}
          </div>
        </div>
      </div>
    </div>

    <!--loading component will be shown for a set time until the card component renders underneath-->
    <vue-content-loading
      :width="160"
      :height="120"
      :speed="2"
      secondary="#efefef"
      v-if="this.showLoader"
      class="loading-component-landscape"
    >
      <rect x="0" y="0" rx="12" ry="12" width="160" height="105" />
    </vue-content-loading>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/components/_landscape-card.scss";
</style>
