<script>
export default {
  name: "Loader"
};
</script>

<template>
  <div class="favicon-loader">
    <img
      class="favicon-loader-img animate__animated animate__infinite"
      src="@/assets/mainLogoLightBackground.webp"
      alt="Sigiriya Rock"
    />

    <div class="loading-dots">
      <div class="loading-dots--dot"></div>
      <div class="loading-dots--dot"></div>
      <div class="loading-dots--dot"></div>
      <div class="loading-dots--dot"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "/src/styles/components/pre-loader.scss";
</style>
