<template>
  <swiper class="swiper" :options="swiperOption">
    <swiper-slide v-for="image in cardData.images">
      <div>
        <div
          :class="[isFullwidth ? 'fullwidth-swiper-image' : 'swiper-image']"
          :style="{
            backgroundImage: `linear-gradient( 2.51deg,
      rgba(0, 0, 0, 0.5) 2.02%,
      rgba(2, 20, 31, 0.5) 27.4%,
      rgba(203, 227, 241, 0.118196) 62.57%,
      rgba(255, 255, 255, 0) 95.71%
    ), url('${image}')`
          }"
        ></div>
      </div>
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  name: "swiper-component",
  title: "Swiper",
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    isMobile: {
      type: Boolean
    },
    isFullwidth: {
      type: Boolean
    },
    imageList: {
      type: [String]
    }
  },
  directives: {
    swiper: directive
  },
  computed() {},
  data() {
    return {
      cardData: {
        images: this.imageList
      },
      swiperOption: {
        loop: true,
        initialSlide: 0,
        grabCursor: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: true
        },
        speed: 1000,
        slidesPerView: "auto",
        spaceBetween: 30,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          type: "bullets"
        }
      }
    };
  },
  beforeMount() {},
  mounted() {
    this.mySwiper.slideTo(3, 1000, false);
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/components/_swiper.scss";
</style>
