<script>
import CommonLayout from "@/views/CommonLayout.vue";
import Swiper from "@/components/Swiper.vue";
import PortraitCard from "@/components/PortraitCard.vue";
import Pagination from "@/components/Pagination.vue";
import BootstrapModal from "@/components/BootstrapModal.vue";
import ErrorComponent from "@/components/APIError.vue";
import EmptyDataComponent from "@/components/EmptyDataContainer.vue";

import * as categoryService from "@/api/services/category.service";
import * as experienceService from "@/api/services/experience.service";
import * as destinationService from "@/api/services/destination.service";

import { swapElement } from "../helpers/arrayReOrder";

export default {
  name: "DestinationPage",
  components: {
    CommonLayout,
    Swiper,
    PortraitCard,
    Pagination,
    BootstrapModal,
    ErrorComponent,
    EmptyDataComponent
  },
  props: {
    cardData: {
      type: Object
    }
  },
  beforeMount() {
    window.scrollTo({ top: 0, left: 0 });
    this.detectMob();
  },
  computed: {
    checkLoading() {
      return this.isLoading;
    },
    checkDataAvailable() {
      if (this.experiencePlaces.length > 0) {
        return true;
      }
      return false;
    }
  },
  data() {
    return {
      heightStyle: {
        height: "60px"
      },
      seeMoreActivated: false,
      destination: null,
      isLoading: true,
      cardsPerPage: 8,
      currentPage: 1,
      totalPages: 0,
      experiencePlaces: [],
      experienceTypes: [],
      locations: [],
      selectedCategory: null,
      showErrorComponent: false
    };
  },
  mounted() {
    this.getDestinationById();
    this.getExperiences();
    this.getExperienceTypes();
  },
  methods: {
    activateSeeMore() {
      this.seeMoreActivated = true;
    },
    deactivateSeeMore() {
      this.seeMoreActivated = false;
    },
    handleLocationFilterButtonPressed(value) {
      this.locations = this.locations.map(location => {
        if (location.destinationId === value.destinationId) {
          return {
            location: location.location,
            selected: !location.selected,
            destinationId: location.destinationId
          };
        }

        return location;
      });
    },
    handleTypeFilterButtonPressed(value) {
      this.experienceTypes = this.experienceTypes.map(experience => {
        if (experience.categoryId === value.categoryId) {
          return {
            type: experience.type,
            selected: !experience.selected,
            categoryId: experience.categoryId
          };
        }

        return experience;
      });
    },
    handleApplyButtonPressed(filters) {
      this.selectedCategory =
        filters.experienceCategories && filters.experienceCategories.length > 0
          ? filters.experienceCategories
          : null;
      this.getExperiences();
    },
    handleResetButtonPressed() {
      this.locations = this.locations.map(location => ({
        location: location.location,
        selected: false,
        destinationId: location.destinationId
      }));
      this.experienceTypes = this.experienceTypes.map(experience => ({
        type: experience.type,
        selected: false,
        categoryId: experience.categoryId
      }));
    },
    detectMob() {
      if (window.innerWidth <= 767) {
        this.isMobile = true;
      }
    },
    async getDestinationById() {
      try {
        const destinationId = this.$route.params.destinationId;
        this.destination = await destinationService.getDestinationById(destinationId);
      } catch (error) {
        this.showErrorComponent = true;
      }
    },
    async getExperiences() {
      this.isLoading = true;
      try {
        this.isLoading = true;
        const destinationId = this.$route.params.destinationId;
        const response = await experienceService.getExperienceList({
          categoryId: this.selectedCategory,
          destinationId,
          itemsPerPage: this.cardsPerPage,
          page: this.currentPage
        });

        this.experiencePlaces = response.data;
        this.totalPages = Math.ceil(response.count / this.cardsPerPage);
      } catch (error) {
        this.error = error.message || "Fetching Popular Experiences failed!";
        console.error("error", this.error);
      }
      this.isLoading = false;
    },
    async getExperienceTypes() {
      try {
        const response = await categoryService.getAllCategories();
        if (response.length > 0) {
          this.experienceTypes = response.map(type => ({
            type: type.categoryName,
            categoryId: type.id,
            selected: false
          }));
        }
        //array elements swapped in order to take up space in filter modal
        this.experienceTypes = swapElement(this.experienceTypes, 3, 5);
      } catch (error) {
        this.error = error.message || "Fetching Experience types failed!";
        console.error("error", this.error);
      }
    }
  }
};
</script>

<template>
  <CommonLayout>
    <div v-if="this.showErrorComponent">
      <ErrorComponent />
    </div>
    <div v-else>
      <div class="content">
        <div class="main-title animate__animated animate__fadeInDown">
          <span>
            {{ destination.destinationTitle }}
          </span>
          <div class="detail-location-row">
            <div class="detail-location-txt">
              {{ destination.destinationProvince }}
            </div>
          </div>
        </div>
        <div class="cover-container">
          <div class="swiper-wrapper animate__animated animate__fadeInLeft animate__slow">
            <Swiper :isMobile="this.isMobile" :imageList="destination.destinationImageList" />
          </div>
          <div class="desc-wrapper animate__animated animate__fadeInRight animate__slow">
            <div v-if="isMobile">
              <p class="adjustable-desc" :style="[seeMoreActivated ? 'height: 100%' : heightStyle]">
                {{ destination.destinationDescription }}
              </p>
              <span class="desc-more" v-if="!seeMoreActivated" @click="activateSeeMore">
                <i class="fas fa-angle-down"></i>Read more
              </span>
              <span class="desc-more" v-if="seeMoreActivated" @click="deactivateSeeMore">
                <i class="fas fa-angle-up"></i> Read Less
              </span>
            </div>
            <div v-else class="desc-container">
              <p>
                {{ destination.destinationDescription }}
              </p>
            </div>
          </div>
        </div>

        <!-- Popular Destinations section -->
        <div style="width: 100%">
          <div class="heading-container animate__animated animate__fadeInUp">
            <div class="page-title">
              {{ $t("popular-experiences.page-title") }}
            </div>
            <BootstrapModal
              :locations="locations"
              :experienceTypes="experienceTypes"
              showExperienceType
              @onApplyButtonPressed="handleApplyButtonPressed"
              @onResetButtonPressed="handleResetButtonPressed"
              @onLocationFilterButtonPressed="handleLocationFilterButtonPressed"
              @onTypeFilterButtonPressed="handleTypeFilterButtonPressed"
            />
          </div>
          <div v-if="checkLoading" class="loading-container">
            <b-spinner label="Loading..." variant="secondary"></b-spinner>
          </div>
          <div class="card-outer-container-wrapper">
            <div class="card-outer-container">
              <PortraitCard
                id="portrait-card"
                v-for="place in experiencePlaces"
                :key="place.destinationName"
                :cardData="place"
                :isExperience="true"
              />
            </div>
            <div class="pagination-container">
              <div v-if="checkDataAvailable" class="pagination-wrapper">
                <Pagination
                  :total-pages="this.totalPages"
                  :per-page="cardsPerPage"
                  :current-page="currentPage"
                  @pagechanged="onPageChange"
                />
              </div>
              <div v-else class="noexperiences-text">
                Oops! No experience available for selected type(s).
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </CommonLayout>
</template>

<style lang="scss" scoped>
@import "/src/styles/views/_destination-page.scss";
</style>
