<script>
import CommonLayout from "@/views/CommonLayout.vue";
import Swiper from "@/components/Swiper.vue";
import AppButton from "@/components/AppButton.vue";
import ReviewCard from "@/components/ReviewCard.vue";
import ErrorComponent from "@/components/APIError.vue";

import * as experienceService from "@/api/services/experience.service";
import StickyBooker from "../components/StickyBooker.vue";
import GmapCustomMarker from "vue2-gmap-custom-marker";

export default {
  name: "DetailPage",
  components: {
    CommonLayout,
    Swiper,
    AppButton,
    ReviewCard,
    StickyBooker,
    ErrorComponent,
    "gmap-custom-marker": GmapCustomMarker
  },
  props: {
    cardData: {
      type: Object
    }
  },
  beforeMount() {
    window.scrollTo({ top: 0, left: 0 });
    this.detectMob();
  },
  mounted() {
    this.getExperienceById();
    this.reviewBgStyle();
    this.checkReviews();
  },

  data() {
    return {
      heightStyle: {
        height: "60px"
      },
      seeMoreActivated: false,
      experienceId: null,
      experience: null,
      totPrice: null,
      haveReviews: true,
      reviewStyle: {
        type: Object,
        default: null
      },
      markers: [],
      showErrorComponent: false,
      options: {
        disableDefaultUI: true,
        zoom: 12,
        zoomControl: true,
        disableDoubleClickZoom: true,
        mapTypeControl: false,
        scaleControl: false,
        scrollwheel: false,
        panControl: false,
        streetViewControl: false,
        draggable: true,
        overviewMapControl: true,
        overviewMapControlOptions: {
          opened: false
        },
        styles: [
          {
            elementType: "geometry",
            stylers: [
              {
                color: "#f5f5f5"
              }
            ]
          },
          {
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#616161"
              }
            ]
          },
          {
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#f5f5f5"
              }
            ]
          },
          {
            featureType: "administrative.land_parcel",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#bdbdbd"
              }
            ]
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
              {
                color: "#eeeeee"
              }
            ]
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#757575"
              }
            ]
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [
              {
                color: "#e5e5e5"
              }
            ]
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e"
              }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [
              {
                color: "#ffffff"
              }
            ]
          },
          {
            featureType: "road.arterial",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#757575"
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [
              {
                color: "#dadada"
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#616161"
              }
            ]
          },
          {
            featureType: "road.local",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e"
              }
            ]
          },
          {
            featureType: "transit.line",
            elementType: "geometry",
            stylers: [
              {
                color: "#e5e5e5"
              }
            ]
          },
          {
            featureType: "transit.station",
            elementType: "geometry",
            stylers: [
              {
                color: "#eeeeee"
              }
            ]
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                color: "#c9c9c9"
              }
            ]
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e"
              }
            ]
          }
        ]
      }
    };
  },
  methods: {
    activateSeeMore() {
      this.seeMoreActivated = true;
    },
    deactivateSeeMore() {
      this.seeMoreActivated = false;
    },
    detectMob() {
      if (window.innerWidth <= 599) {
        this.isMobile = true;
      }
    },
    async getExperienceById() {
      try {
        this.experienceId = this.$route.params.experienceId;
        this.experience = await experienceService.getExperiencesById(this.experienceId);
        let expreienceCost = this.experience.experiencePrice;
        this.thousandSeperator(expreienceCost);
        this.checkReviews();
      } catch (error) {
        this.showErrorComponent = true;
      }
    },
    //method to add comma seperator to price
    thousandSeperator(price) {
      this.totPrice = price.toLocaleString("si-LK");
    },
    //method to change styling for navbar if on homepage
    reviewBgStyle: function() {
      this.reviewStyle = {
        //background: "transparent"
      };
      console.log(this.reviewStyle);
    },
    //go to booking page with experience ID
    navigateToBooking() {
      this.$router.push({
        name: "Booking",
        params: {
          experienceId: this.experienceId
        }
      });
    },
    //check whether reviews are available
    checkReviews() {
      //if no review is available the review section is not displayed
      if (this.experience.reviews[0] === undefined) {
        this.haveReviews = false;
      }
    }
  }
};
</script>

<template>
  <CommonLayout>
    <div v-if="this.showErrorComponent">
      <ErrorComponent />
    </div>
    <div>
      <div class="content">
        <div class="main-title animate__animated animate__fadeInDown">
          <span>
            {{ experience.experienceTitle }}
            <!-- {{ cardData.experienceTitle }} -->
          </span>
          <div class="detail-location-row">
            <i class="fas fa-map-marker-alt location-icon"></i>
            <div class="detail-location-txt">
              {{
                experience &&
                  experience.experienceDestination &&
                  experience.experienceDestination.destinationTitle
              }}
              <!-- {{ cardData.experienceDestination.destinationTitle }} -->
            </div>
          </div>
        </div>
        <div class="swiper-wrapper animate__animated animate__fadeInUp animate__slow">
          <Swiper
            :isMobile="this.isMobile"
            :isFullwidth="true"
            :imageList="experience.experienceImages"
          />
        </div>
        <div class="content-container animate__animated animate__fadeInUp animate__slow">
          <div class="left-container">
            <!-- Description -->
            <div v-if="isMobile">
              <p class="adjustable-desc" :style="[seeMoreActivated ? 'height: 100%' : heightStyle]">
                {{ experience.experienceDescription }}
              </p>
              <span class="desc-more" v-if="!seeMoreActivated" @click="activateSeeMore">
                <i class="fas fa-angle-down"></i>Read more
              </span>
              <span class="desc-more" v-if="seeMoreActivated" @click="deactivateSeeMore">
                <i class="fas fa-angle-up"></i> Read Less
              </span>
            </div>
            <div v-else class="desc-container">
              <p>
                {{ experience.experienceDescription }}
              </p>
            </div>
            <!-- Experience Key Info Component -->
            <div class="keyinfo-container">
              <span>Key Info</span>
              <ul>
                <li v-for="keyInfo in experience.experienceKeyInfo" :key="keyInfo.id">
                  <img v-bind:src="keyInfo.keyInfoIcon" />
                  <p>{{ keyInfo.keyInfoName }}</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="right-container">
            <!-- Book Now Component -->
            <div class="booknow-container">
              <div class="price">
                <span>LKR {{ this.totPrice }}</span>
                <p>Per Person</p>
              </div>
              <div class="button-container">
                <button class="booknow-button" @click="navigateToBooking">
                  {{ $t("detailPage.booknow-btn") }}
                </button>
              </div>
            </div>
            <!-- Time Slots Component -->
            <div class="time-container">
              <span>Timeslots</span>
              <ul>
                <li>
                  <p><i class="ri-calendar-line time-icon"></i> Weekdays</p>
                  <p>{{ experience.experienceAvailableTime.weekdays }}</p>
                </li>
                <li>
                  <p><i class="ri-calendar-line time-icon"></i> Weekends</p>
                  <p>{{ experience.experienceAvailableTime.weekend }}</p>
                </li>
              </ul>
            </div>
            <!-- Map Component -->
            <div class="map-container" id="map">
              <GmapMap
                :center="{ lat: experience.latitude, lng: experience.longitude }"
                map-type-id="roadmap"
                :options="options"
                style="width: 100%; height: 260px"
              >
                <gmap-custom-marker
                  :marker="{ lat: experience.latitude, lng: experience.longitude }"
                >
                  <img
                    src="../assets/icons/marker.png"
                    alt="marker"
                    style="margin-bottom: -30px; margin-left: -1px"
                  />
                </gmap-custom-marker>
              </GmapMap>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Review section -->
    <div class="reviews" v-if="haveReviews">
      <div class="margin">
        <div class="review-title">
          <p>{{ $t("homePage.travel-review") }}</p>
        </div>
        <div class="horizontal-view-wrapper">
          <div v-if="showErrorComponent" class="home-error-text home-error-bottom-margin">
            <span>{{ $t("api-error.error-message") }}</span>
          </div>
          <vue-horizontal
            ref="horizontal"
            class="horizontal horizontal-view"
            :button="isMobile ? false : true"
            v-else
          >
            <ReviewCard
              class="review-card"
              v-for="review in experience.reviews"
              :key="review.id"
              :cardData="review"
            />
          </vue-horizontal>
        </div>
      </div>
    </div>
    <!--Sticky booker section-->
    <sticky-booker :experience="this.experience" :totPrice="this.totPrice" />
  </CommonLayout>
</template>

<style lang="scss" scoped>
@import "/src/styles/views/_detail-page.scss";
@import "/src/styles/_shared.scss";
</style>
