import { ApiCall } from "../apiBase";

export async function getTravelerReviewList(filter) {
  let reviews = [];

  const { itemCount } = filter || {};

  const result = await ApiCall({
    method: "post",
    data: {
      query: `
            query Query($getTravelerReviewListItemCount: Int) {
                getTravelerReviewList(itemCount: $getTravelerReviewListItemCount) {
                    id
                    reviewerName
                    review
                    rating
                    reviewerImage
                }
            }
            `,
      variables: {
        getTravelerReviewListItemCount: itemCount
      }
    }
  });

  if (!result.data.errors && result.data.data.getTravelerReviewList) {
    reviews = result.data.data.getTravelerReviewList;
  }
  else {
    throw Error(result?.data?.errors[0]);
  }

  return reviews;
}
