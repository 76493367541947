<script>
export default {
  name: "AppButton",
  props: {
    title: {
      type: String,
      default: "Button"
    },
    appButtonClass: {
      type: String,
      default: ""
    },
    iconShow: {
      type: Boolean,
      default: false
    },
    iconPath: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      defaultClass: "common"
    };
  },
  methods: {
    handleButtonPressed() {
      this.$emit("onButtonPressed");
    }
  }
};
</script>

<template>
  <div
    type="button"
    @click="handleButtonPressed"
    :disabled="false"
    :class="[appButtonClass, defaultClass]"
  >
    <img :src="iconPath" class="icon" v-show="iconShow" />{{ title }}
  </div>
</template>

<style scoped lang="scss">
@import "@/styles/components/_app-button.scss";
</style>
