<script>
export default {
  name: "ReviewCard",
  props: {
    cardData: {
      type: Object
    }
  },
  methods: {}
};
</script>

<template>
  <div class="card-container">
    <div class="top-container">
      <div class="left-container">
        <div class="review-image">
          <img alt="Avatar" :src="cardData.reviewerImage" width="100%" height="100%" />
        </div>
      </div>
      <div class="right-container">
        <div class="name">
          {{ cardData.reviewerName }}
        </div>
        <div class="star-review">
          <img class="star-image" alt="Vue logo" src="@/assets/star.webp" />
          <div class="review-rate">{{ cardData.rating }}</div>
        </div>
      </div>
    </div>
    <div class="description">
      <p>
        {{ cardData.review }}
      </p>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/components/new-review-card.scss";
</style>
