<template>
  <CommonLayout>
    <div v-if="this.showErrorComponent">
      <ErrorComponent noDestination/>
    </div>
    <div v-else>
      <div class="content">
        <div
          style="z-index: 10000 !important"
          class="heading-container break-rows-on-mobile animate__animated animate__fadeInDown"
        >
          <div class="page-title">
            {{ $t("discover-destinations.page-title") }}
          </div>
          <Search
            @on-item-selected="
              dropdownSelection = $event;
              onItemSelect($event);
            "
            searchTerm="searchTerm"
            @on-item-reset="dropdownSelection = {}"
            @searchDestination="searchDestinations($event)"
          />
        </div>
        <div v-if="checkLoading" class="loading-container">
          <b-spinner label="Loading..." variant="secondary"></b-spinner>
        </div>
        <!--inline styling applied to make search results appear above div since external stylesheet does not work-->
        <div
          class="card-outer-container-wrapper animate__animated animate__fadeIn animate__slower 2s"
          v-if="checkDataAvailable"
        >
          <div class="card-outer-container">
            <LandscapeCard
              id="portrait-card"
              v-for="place in destinationPlaces"
              :key="place.id"
              :cardData="place"
              :destinationsPage="true"
            />
          </div>
          <div class="pagination-container">
            <div class="pagination-wrapper">
              <Pagination
                :total-pages="this.totalPages"
                :per-page="cardsPerPage"
                :current-page="currentPage"
                @pagechanged="onPageChange"
              />
            </div>
          </div>
        </div>
        <div v-else>
          <empty-data-container :description="this.$t('api-error.no-destination')" />
        </div>
      </div>
    </div>
  </CommonLayout>
</template>

<script>
import * as services from "@/services/index";
import * as destinationService from "@/api/services/destination.service";
import LandscapeCard from "@/components/LandscapeCard.vue";
import Pagination from "@/components/Pagination.vue";
import CommonLayout from "@/views/CommonLayout.vue";
import Search from "@/components/Search.vue";
import VueContentLoading from "vue-content-loading";
import EmptyDataContainer from "../components/EmptyDataContainer.vue";
import ErrorComponent from "@/components/APIError.vue";

export default {
  name: "Home",
  components: {
    LandscapeCard,
    Pagination,
    CommonLayout,
    Search,
    VueContentLoading,
    EmptyDataContainer,
    ErrorComponent
  },
  data() {
    return {
      isLoading: true,
      error: null,

      //the isLoading objects are used to render loading components
      destinationPlaces: [
        { isLoading: true },
        { isLoading: true },
        { isLoading: true },
        { isLoading: true },
        { isLoading: true },
        { isLoading: true }
      ],

      currentPage: 1,
      isModalVisible: false,
      locations: [],
      cardsPerPage: 12,
      totalPages: 0,
      searchTerm: null,
      itemList: ["1", "2", "3"],
      dropdownSelection: {},
      showErrorComponent: false
    };
  },
  computed: {
    checkLoading() {
      return this.isLoading;
    },
    checkDataAvailable() {
      if (this.destinationPlaces.length > 0) {
        return true;
      }
      return false;
    }
  },
  beforeMount() {
    window.scrollTo({ top: 0, left: 0 });
  },
  mounted() {
    this.detectMob();
    this.initDiscoverDestinations();
  },
  methods: {
    async getDestinations() {
      this.isLoading = true;

      console.log(this.destinationPlaces.length);

      const filters = { page: this.currentPage, itemsPerPage: this.cardsPerPage };

      try {
        if (this.searchTerm) {
          filters.searchTerm = this.searchTerm;
        }
        await this.getDestinationList(filters);
      } catch (error) {
        this.showErrorComponent = true;
      }
    },
    async onItemSelect(item) {
      try {
        this.searchTerm = item.destinationTitle;
        this.getDestinations();
      } catch (error) {
        this.error = error.message || "Fetching Locations failed!";
        console.error("error", this.error);
      }
    },
    async searchDestinations(searchTerm) {
      try {
        this.searchTerm = searchTerm;
        this.getDestinations();
      } catch (error) {
        this.error = error.message || "Fetching Locations failed!";
        console.error("error", this.error);
      }
    },
    onPageChange(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        this.getDestinations();
        window.scrollTo({ top: 0, left: 0 });
      }
    },
    detectMob() {
      if (window.innerWidth <= 800) {
        this.cardsPerPage = 6;
        this.destinationPlaces = [
          { isLoading: true },
          { isLoading: true },
          { isLoading: true },
          { isLoading: true },
          { isLoading: true },
          { isLoading: true }
        ];
      }
    },
    async getDestinationList(filters) {
      try {
        const destinationResponse = await destinationService.getAllDestinationsPaginated(filters);
        //if only one search result matches the selected item, user will be directed to the respective destination page
        if (destinationResponse.data.length === 1) {
          this.$router.push({ name: "DestinationPage", params: { destinationId: destinationResponse.data[0].id } });
        } else {
          this.destinationPlaces = destinationResponse.data;
          this.totalPages = Math.ceil(destinationResponse.count / this.cardsPerPage);
        }
      } catch (error) {
        this.error = error.message || "Fetching destinations failed!";
        console.error("error", this.error);
      }
      this.isLoading = false;
    },
    initDiscoverDestinations() {
      const searchTerm = this.$route.query.searchTerm;

      if (searchTerm) {
        this.$router.replace({ query: {} });
        this.searchTerm = searchTerm;
      }
      this.getDestinations();
    }
  }
};
</script>

<style scoped lang="scss">
@import "/src/styles/views/_discover-destinations.scss";
</style>
