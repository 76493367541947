import Home from "../views/Home.vue";
import Experience from "../views/ExperienceCategory.vue";
import PopularExperiences from "../views/PopularExperiences.vue";
import ExperienceDetail from "../views/DetailPage.vue";
import DestinationPage from "../views/DestinationPage.vue";
import DiscoverDestinations from "../views/DiscoverDestinations.vue";

export default [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/destinations",
    name: "PopularExperiences",
    component: DiscoverDestinations
  },
  {
    path: "/experiences/popular",
    name: "PopularExperiences",
    component: PopularExperiences
  },
  {
    path: "/experiences/category/:categoryId",
    name: "ExperienceCategory",
    component: Experience
  },
  {
    path: "/experiences/:experienceId",
    name: "ExperienceDetail",
    component: ExperienceDetail,
    meta: { transitionName: "slide" }
  },
  {
    path: "/destination/:destinationId",
    name: "DestinationPage",
    component: DestinationPage
  },
  {
    path: "/our_story",
    name: "OurStory",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/booking/:experienceId",
    name: "Booking",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "booking" */ "../views/BookingDetailsInput.vue"),
    meta: { transitionName: "slide" }
  },
  {
    path: "/booking_contact/:experienceId",
    name: "BookingContact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "booking" */ "../views/BookingContactDetails.vue"),
    meta: { transitionName: "slide" }
  },
  {
    path: "/booking_payment/:experienceId",
    name: "BookingPayment",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "booking" */ "../views/BookingPaymentDetails.vue"),
    meta: { transitionName: "slide" }
  },
  {
    path: "/booking_summary/:experienceId",
    name: "BookingSummary",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "booking" */ "../views/BookingSummary.vue"),
    meta: { transitionName: "slide" }
  },
  {
    path: "/terms&conditions",
    name: "TermsConditions",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/TermsConditions.vue")
  },
  {
    path: "/privacy_policy",
    name: "Policy",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Policy.vue")
  },
  {
    path: "/contact_us",
    name: "Contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Contact.vue")
  },
  {
    path: "/error",
    name: "ErrorPage",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../components/APIError.vue")
  },
  {
    path: "/*",
    component: () => import("../views/404.vue")
  },
  {
    path: "/*",
    redirect: "/"
  }
];
