<script>
export default {
  name: "Error",
  props: {
    noDestination: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<template>
  <div class="error-page-wrapper">
    <img alt="Page not found" src="../assets/no-data-found.webp" class="error-image" />
    <span v-if="noDestination" class="error-text"
      >{{ $t("api-error.no-destination") }}
      <a href="/contact_us" class="hyperlink" target="_blank">Contact us </a>if you need some help.
    </span>
    <span v-else class="error-text"
      >{{ $t("api-error.error-message") }}
      <a href="/contact_us" class="hyperlink" target="_blank">Contact us </a>if you need some help.
    </span>
  </div>
</template>

<style scoped lang="scss">
@import "/src/styles/components/error-page.scss";
</style>
