<script>
export default {
  name: 'FilterButton',
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Button',
    },
  },
  data() {
    return {
      filterButtonClass: 'btn-filter',
    };
  },
  methods: {
    onClickFilter(title) {
      this.$emit('onFilterButtonPressed', title);
    },
  },
};
</script>

<template>
  <div
    type="button"
    @click="onClickFilter(title)"
    :disabled="false"
    :class="[{ active: selected }, filterButtonClass]"
  >
    {{ title }}
  </div>
</template>

<style scoped lang="scss">
@import "@/styles/components/_filter-button.scss";
</style>
