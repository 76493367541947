<script>
export default {
  name: "StickyBooker",
  props: {
    experience: {
      type: Object
    },
    totPrice: String
  },

  data: function() {
    return {
      bgStyle: {
        type: Object,
        default: null
      },
      scrollPosition: 580
    };
  },

  methods: {
    handleScroll() {
      let windowHeight = window.innerHeight - 300;
      console.log(window.innerHeight);
      let homeScrollDown = window.scrollY;

      if (window.outerWidth > 900) {
        if (homeScrollDown > windowHeight) {
          this.bgStyle = {
            transition: "all 0.5s ease-in",
            opacity: "100%",
            height: "85px"
          };
        } else if (homeScrollDown < this.scrollPosition) {
          this.bgStyle = {
            transition: "all 0.5s ease-out",
            opacity: "0%",
            height: "0%"
          };
        }
      }
    },
    //go to booking page with experience ID
    navigateToBooking() {
      this.$router.push({
        name: "Booking",
        params: {
          experienceId: this.experience.id
        }
      });
    }
  },

  //calling method as soon as component mounts
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  }
};
</script>

<template>
  <header class="sticky-header" :style="bgStyle">
    <nav class="sticky-nav">
      <div>
        <div class="sticky-main-title">
          <span>
            {{ experience.experienceTitle }}
          </span>
          <div class="sticky-location-row">
            <i class="fas fa-map-marker-alt sticky-location-icon"></i>
            <div class="sticky-location-txt">
              {{ experience.experienceDestination.destinationTitle }}
            </div>
          </div>
        </div>
        <div class="right-container">
          <div class="booknow-container">
            <div class="price">
              <span>LKR {{ totPrice }}</span>
              <p>Per Person</p>
            </div>
            <div>
              <button class="bookNow-btn-sticky" @click="navigateToBooking()">
                {{ $t("detailPage.booknow-btn") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/styles/components/sticky-booker.scss";
</style>
